@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Roboto&display=swap");
@import url("https://themes.googleusercontent.com/fonts/css?kit=xTOoZr6X-i3kNg7pYrzMss5-x8zqu-667uHWljev_4A");

/* code for professional experience: */
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}

.carot-rotated {
  transform: rotate(180deg); /* Rotate the arrow when expanded */
  transition: transform 0.3s ease; /* Add transition for smooth flipping */
}

.bottom-wordcloud {
  display: none;
}

.scottkutler {
  object-fit: cover;
  object-position: center center;
}

.c2 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: "Roboto";
  font-style: normal;
}
.c0 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 13pt;
  font-family: "Roboto";
  font-style: normal;
}
.c3 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Roboto";
  font-style: normal;
}
.c1 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Roboto";
  font-style: normal;
  color: white;
  text-align: left;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
  color: white;
}
.c9 {
  font-weight: 700;
  text-decoration: none;
  font-size: 12pt;
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  color: white;
}
.c13 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Roboto";
  font-style: normal;
  color: white;
}
.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.35;
  margin-bottom: 10px;
  /* text-justify: off; */

  orphans: 2;
  widows: 2;
  text-align: left;
  color: white;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  color: white;

  orphans: 2;
  widows: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c7 {
  font-size: 10pt;
  font-family: "Roboto";
  color: white;
  font-weight: 700;
}
.c8 {
  font-size: 10.5pt;
  font-family: "Roboto";
  color: white;

  font-weight: 400;
}
.c11 {
  font-size: 10pt;
  font-family: "Roboto";
  font-weight: 400;
  color: white;
}
.c14 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c15 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: italic;
}
.c20 {
  width: 0;
  height: 0;
  transition: transform 0.3s ease;

  margin-right: 15px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  transform-origin: center;
  border-top: 8px solid rgba(204, 204, 204, 0.829);
}
.c21 {
  font-size: 25px;
  margin-left: 5px;
}
.c22 {
  margin-top: 4px;
  font-size: 25px;
  margin-left: 5px;
}

.c23 {
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: -10px;
}
.c24 {
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  color: rgb(233, 233, 233);
  margin-bottom: -10px;
}

.c12 {
  background-color: #000000;
  max-width: 432pt;
  padding: 18pt 90pt 18pt 90pt;
  color: white;
}

.c27 {
  font-size: 20px;
  margin-left: 7px;
}
.c10 {
  height: 11pt;
  color: white;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  color: white;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  color: white;

  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  color: white;

  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  color: white;

  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
/*  */
/* Code for rest of site: */
/*  */

body {
  /* background: rgb(16, 53, 93); */
  background-image: linear-gradient(140deg, #012056 0%, #000000 60%);
  color: white;
}

.intro-background {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
}

.WorkPanel-container {
  width: 650px;
  margin-top: -10px;
}

.mywork-section {
  padding-top: 20px;
  margin-top: -50px;
  padding-bottom: 40px;
  margin-bottom: -10px;
  transition: 2s;
}

.portfolio-container {
  padding: 13px 12px 10px 12px;
  background-color: rgb(66, 66, 66);
  transition: 0.5s;
  /* border-radius: 10px; */
  width: 350px;
  height: 275px;
  font-family: Roboto;
  transition: 1s;
  border: 1px solid black;
}

.portfolio-container:hover {
  border: 1px solid rgb(105, 105, 105);
  background-color: rgb(17, 17, 17);
}

.portfolio-container-main {
  margin: 0px 90px 0px 90px;
  row-gap: 45px;
  margin-top: 50px;
  display: grid;
  grid-template-rows: 6;
  grid-template-columns: 3;
  grid-template-areas:
    "header header header "
    "footer footer footer  ";
  justify-items: center;
  align-items: center;
  text-align: left;
}
.expansion-spacing {
  height: 8px;
}
.full-resume-container {
  border: 0px solid black;
  margin-top: 75px;
  margin-bottom: -500px;
  padding-bottom: 0;
}

.full-resume-button {
  border: 0px solid black;
  display: flex;
  justify-content: center;
  margin: 15px;
}

.full-resume-button2 {
  border: 0px solid black;
  display: flex;
  justify-content: center;
  margin: 0px;
  margin: -10px 0px -10px 0px;
}

.full-resume-content {
  border: 0px solid black;
  height: 600px;
  width: 50%;
}

.Social-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  z-index: 10;
  margin-top: 10px;
  margin-bottom: 20px;
}

.Social-box {
  height: 5px;
  margin: 10px;
}

.resume-download-top {
  margin-top: 10px;
  margin-bottom: 20px;

  border: 1px solid rgba(255, 255, 255, 0.603);
  color: white;
  background-color: rgb(22, 22, 22);
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  border-radius: 2%;
  width: 200px;
}

.resume-button {
  margin: 0px 10px 0px 10px;
  border: 1px solid rgba(255, 255, 255, 0.603);
  color: white;
  background-color: rgb(22, 22, 22);
  padding: 15px 0px 15px 0px;
  text-align: center;
  width: 300px;
  border-radius: 2%;
  transition: 0.75s;
}

.resume-button:hover {
  border: 1px solid rgba(37, 37, 37, 0.603);
  color: black;
  background-color: #84b1ff;
}

.resume-download-bottom {
  margin-top: 10px;
  margin-bottom: 50px;
  border: 1px solid rgba(255, 255, 255, 0.603);
  color: white;
  background-color: rgb(22, 22, 22);
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  border-radius: 2%;
  width: 200px;
  transition: 0.75s;
}

.resume-download-bottom:hover {
  border: 1px solid rgba(37, 37, 37, 0.603);
  color: black;
  background-color: white;
}

h1 {
  padding-top: 20pt;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Roboto";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Roboto";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Roboto";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: Roboto;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: Roboto;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Roboto";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  font-family: Roboto;
  widows: 2;
  text-align: left;
}

.resume-page {
  text-align: center;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
.about-page {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.portfolio-text {
  margin-left: 5px;
}

.portfolio-card {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  margin: 15px 10px 10px 10px;
}

.portfolio-headline {
  font-weight: 900;
  text-align: left;
}

.portfolio-image {
  width: 374px;
  transition: 1s;
  margin-bottom: 5px;
  margin-top: -13.25px;
  z-index: 100;
  margin-left: -12px;
  /* border-radius: 8px; */
  transition: 1s;
  /* border: 1px solid rgb(37, 37, 37); */
}
/*  */
/* .portfolio-image:hover { */
/* border: 1px solid rgb(105, 105, 105); */
/* } */

.portfolio-description {
  font-style: italic;
  font-size: 13px;
  color: rgb(187, 187, 187);
  text-align: left;
  margin-left: 40px;
}

.portfolio-gitlink {
  font-size: 13px;
  color: rgb(207, 207, 207);
  text-align: left;
}

.portfolio-thumbnail {
  background: white;
  height: 200px;
}

.fade-in-slower {
  animation: fadeIn ease 6s;
  -webkit-animation: fadeIn ease 6s;
  -moz-animation: fadeIn ease 6s;
  -o-animation: fadeIn ease 6s;
  -ms-animation: fadeIn ease 6s;
}

.fade-in-slow {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

.fade-in-fast {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.facebook-button {
  height: 20px;
  width: 20px;
  background-image: url("https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512");
  background-size: contain;
}

.contact-myinfo {
  text-align: center;
  color: rgb(133, 206, 255);
}

.contact-form {
  height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  margin-bottom: 30px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn-Mid {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut-Mid {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SmallContact {
  display: none;
}
.intro-name {
  height: fit-content;
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 900;
  text-transform: uppercase;
  margin: 150px auto 0px;
  padding: 0;
  font-size: 90px;
  line-height: 50px;
}

.intro-subheading {
  height: 32px;
  opacity: 0.95;
  color: #ffffff;
  font-family: Roboto;
  font-weight: 700;
  letter-spacing: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
  padding: 0;
  z-index: 1;
  font-size: 20px;
  text-shadow: 5px solid black;
}

.aboutme-content {
  background-color: rgb(62, 126, 179);
  margin-top: 30px;
  padding-top: 0px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.aboutme-box {
  display: flex;
  flex-direction: row;
  width: 45vw;
  margin-top: 28px;
  transition: 0.5s;
}

.resume-aboutme {
  text-align: center;
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  width: 25vw;
  margin-top: -8px;
  line-height: 22px;
  transition: 1s;
}

.scottkutler {
  margin-right: 35px;
  width: 218px;
  height: 255px;
  border: 5px solid black;
  margin-left: 10px;
}

.resume-container {
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  z-index: -1;
  height: 1000px;
  width: 800px;
  background: rgb(16, 53, 93);
  background: radial-gradient(
    circle,
    rgba(16, 53, 93, 1) 11%,
    rgba(1, 18, 59, 1) 74%
  );
  border: 0px solid white;
}

.resume-embed {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.intro-background-color {
  margin-bottom: 70px;
}

.mywork-header {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  color: #a4c2f4;
  text-align: center;
  font-size: 25px;
  margin-top: 40px;
  margin-bottom: -20px;
}

.contact-header {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  color: #a4c2f4;
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.experience-header {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  color: #a4c2f4;
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
}

.skill-header {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  color: #a4c2f4;
  text-align: center;
  font-size: 25px;
  margin-top: -30px;
  margin-bottom: -15px;
}

.aboutme-header {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  color: #a4c2f4;
  text-align: center;
  font-size: 25px;
  font-family: Roboto;
  margin-top: -20px;
  margin-bottom: -20px;
  display: none;
}

.about-flex-grid {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  margin-top: 30px;
}

.about-h4-left,
.about-h4-right {
  text-align: center;
  margin-top: -10px;
  margin-bottom: -40px;
  text-decoration: underline;
  font-family: Roboto;
  line-height: 20px;

  color: #a4c2f4;
}

.skills-bullet {
  color: #a4c2f4;
  font-size: 12px;
}

.about-h5-left,
.about-h5-right {
  text-align: center;
  line-height: 20px;
  margin-bottom: 35px;
  font-family: Roboto;
}

.col1 {
  width: 20%;
  margin-left: 30px;
}

.col2 {
  margin-left: -120px;
  margin-right: -120px;
}

.col3 {
  width: 20%;
  margin-right: 30px;
}

.col2,
.about-wordcloud {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70%;
}

.col2 {
  margin-top: -10px;
}
.resume-flex-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 50px;
}

.NavBar {
  width: 100vw;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-block;
  background-color: black;
  z-index: 100;
  float: none;
  padding-top: 50px;
  margin-top: -50px;
}

.navbar-component-container {
  background-color: transparent;
  color: #a4c2f4;
  text-align: center;
  margin: 20px auto 20px auto;
  text-decoration: none;
  font-family: Roboto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 15px;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.navbar-component-links {
  margin-left: 30px;
  margin-right: 30px;
  transition: 0.25s;
  font-weight: 800;
  font-family: Roboto;
  text-align: center;
}

.navbar-component-links:hover {
  color: #8bbdff;
  font-weight: 900;
}

a {
  text-decoration: none;
  color: white;
}

h3,
h1 {
  font-family: Roboto;
  color: #a4c2f4;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1500px) {
  .portfolio-container-main {
    row-gap: 15px;
    margin-top: 50px;
    display: grid;
    grid-template-rows: 6;
    grid-template-columns: 3;
    grid-template-areas:
      "header header header   "
      "footer footer footer   ";
  }
}
@media only screen and (max-width: 1300px) {
  .portfolio-container-main {
    row-gap: 45px;
    margin-top: 50px;
    display: grid;
    grid-template-rows: 6;
    grid-template-columns: 2;
    grid-template-areas:
      "header header    "
      "footer footer    ";
  }

  .scottkutler {
    margin-right: 10px;
  }
  .navbar-component-links {
    font-size: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .aboutme-box {
    display: flex;
    flex-direction: column;
    width: 50vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .aboutme-content {
    background-color: rgb(62, 126, 179);
    margin-top: 30px;
    padding-top: 0px;
    padding: 0px;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
  .c23 {
    margin-top: 10px;
    text-align: center;
  }

  .c24 {
    width: 100%;
    text-align: center;
  }
  .resume-aboutme {
    text-align: center;
    width: 70%;
  }
}
@media only screen and (max-width: 1200px) {
  .intro-name {
    font-size: 50x;
    line-height: 140px;
    margin-top: 80px;
  }

  .full-resume-content {
    width: 70%;
  }

  .intro-subheading {
    font-size: 18px;
    letter-spacing: 10px;
    margin-top: -25px;
    text-shadow: 5px 5px solid black;
  }

  .scottkutler {
    margin: 5px 0px 5px 0px;
  }

  .col1 {
    width: 15vw;
  }
  .col3 {
    width: 15vw;
  }
  .about-page {
    margin-top: -40px;
  }

  .aboutme-box {
    display: flex;
    flex-direction: column;
    width: 60.9vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  .portfolio-container-main {
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-flex-grid {
    width: 45vw;
  }
  .col2 {
    display: none;
  }
  .navbar-component-links {
    font-size: 13px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .portfolio-container {
    margin-bottom: 30px;
  }

  .aboutme-box {
    display: flex;
    flex-direction: column;
    width: 60.9vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 900px) {
  .c9,
  .c7,
  .c13,
  .c1 {
    font-size: 13.5px;
  }
  .col1 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .skills-spacer {
    margin-top: -20px;
  }

  .col3 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .intro-name {
    font-size: 50px;
    margin-top: 140px;
    line-height: normal;
  }

  .intro-subheading {
    font-size: 16px;
    margin-top: 0px;
    font-family: Lato;
    letter-spacing: 7px;
  }

  .about-page {
    margin-top: 30px;
  }

  .intro-background {
    height: 100px;
  }

  .navbar-component-links {
    font-size: 15px;
    text-align: center;
    margin-bottom: -5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .aboutme-header {
    margin-top: -70px;
    margin-bottom: -10px;
  }
  /* .navbar-component-container { */
  /* flex-direction: column; */
  /* align-items: center; */
  /* } */
  /* .navbar-component-container { */
  /* display: none; */
  /* } */
  .intro-background-color {
    background-color: transparent;
  }
  /*  */
  /* .WorkPanel-container { */
  /* display: none; */
  /* } */

  .resume-flex-grid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .workcontainer-component {
    width: 80vw;
  }

  .WorkPanel-container {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .full-resume-content {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .aboutme-box {
    display: flex;
    flex-direction: column;
    width: 99.9vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .workcontainer-component {
    width: 100vw;
  }

  .WorkPanel-container {
    width: 100vw;
  }
}
@media (max-width: 650px) {
  /* .about-flex-grid { */
  /* width: 60vw; */
  /* } */
}

@media (max-width: 600px) {
  .SmallContact {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .bottom-wordcloud {
    width: 80vw;
    display: flex;
  }

  .bottom-container {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 60px;
    margin-top: -30px;
  }

  .non-mobile {
    display: none;
  }
  .contactscript {
    display: none;
  }

  .about-h5-left,
  .about-h5-right {
    font-size: 12px;
  }

  .col1,
  .col3 {
    font-size: 12px;
    width: 50vw;
  }

  .about-flex-grid {
    width: 300px;
  }

  .contact-form {
    display: none;
  }
  .c9,
  .c7,
  .c13,
  .c1 {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .animate {
    animation: unset !important;
    opacity: 1 !important;
    transform: unset !important;
  }
  .col1,
  .col3 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .intro-name {
    font-size: 40px;
    margin-top: 120px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .intro-subheading {
    font-size: 14px;
    letter-spacing: 0.4em;
  }

  .intro-background-color {
    height: 80px;
  }

  .portfolio-container {
    padding: 5px 5px 55px 5px;
    background-color: rgb(66, 66, 66);
    transition: 0.5s;
    /* border-radius: 10px; */
    width: 300px;
    height: 200px;
    font-family: Roboto;
    transition: 1s;
    border: 1px solid black;
  }

  .c23,
  .c24 {
    display: none;
  }
  .resume-aboutme {
    margin-top: -20px;
  }

  .portfolio-image {
    width: 310px;
    transition: 1s;
    margin-left: -5px;
    margin-bottom: 5px;
    margin-top: -5px;
    /* border-radius: 8px; */
    transition: 1s;
    /* border: 1px solid rgb(37, 37, 37); */
  }
  .portfolio-description {
    font-size: 12px;
  }

  .portfolio-gitlink {
    font-size: 12px;
  }

  .navbar-component-links {
    font-size: 13px;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -5px;
  }
}

@media (max-width: 430px) {
  .about-flex-grid {
    /* width: 80vw; */
  }
  .about-h5-left,
  .about-h5-right {
    font-size: 11.5px;
  }
}

@media (max-width: 400px) {
  .about-flex-grid {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  .col1 {
    margin-left: 0;
    font-size: 9px;
  }
  .col3 {
    margin-right: 0;
    font-size: 9px;
  }

  .about-h4-left,
  .about-h4-right {
    font-size: 12.5px;
  }

  .intro-name {
    font-size: 30px;
  }
  .intro-subheading {
    font-size: 15px;
    margin-top: 0px;
  }

  .scottkutler {
    height: 191px;
    width: 163px;
  }

  .navbar-component-links {
    font-size: 12px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -5px;
  }
}

@media (max-width: 350px) {
  .col1 {
    margin-left: 0;
    font-size: 8px;
  }
  .col3 {
    margin-right: 0;
    font-size: 8px;
  }
  .intro-name {
    font-size: 30px;
  }
  .intro-subheading {
    font-size: 10px;
    margin-top: 0px;
  }

  .scottkutler {
    height: 191px;
    width: 163px;
  }

  .navbar-component-links {
    font-size: 12px;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
  }
}
.MuiPaper-root {
  background-color: transparent;
}

.intro-background-color {
  background-color: transparent;
}

/* -------------------------------------------------------------- */
/* Animate CSS
-------------------------------------------------------------- */

.fade-in-from-left {
  opacity: 0;
  animation-timing-function: ease-out;
  -webkit-transform: translateX(-15px);
  -moz-transform: translateX(-15px);
  -o-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in-from-left.animated {
  animation-name: fade-from-left;
  animation-duration: 0.5s;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes fade-from-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -o-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }

  45% {
    opacity: 0.5;
  }

  90% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fade-in-from-bottom {
  opacity: 0;
  animation-timing-function: ease-out;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -o-transform: translateY(15px);
  -ms-transform: translateY(15px);
  transform: translateY(15px);
}

.fade-in-from-bottom.animated {
  animation-name: fade-from-bottom;
  animation-duration: 1s;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

@keyframes fade-from-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -o-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
  }
  90% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fade-in-from-right {
  opacity: 0;
  animation-timing-function: ease-out;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -o-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
}

.fade-in-from-right.animated {
  animation-name: fade-from-right;
  animation-duration: 0.5s;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes fade-from-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -o-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }

  90% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
